import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"

import { Container, makeStyles, Typography, Grid } from "@material-ui/core"
import Rating from "@material-ui/lab/Rating"

import SEO from "../components/seo"
import BookList from "../components/book-list"
import PageTitle from "../components/page-title"
import Breadcrumbs from "../components/breadcrumbs"

// import CategoryBg from "../../static/images/mainBg.jpg"

const windowGlobal = typeof window !== "undefined" && window
const documentGlobal = typeof document !== `undefined` && document

const useStyles = makeStyles(theme => ({
  fullWidth: {
    paddingLeft: "initial",
    paddingRight: "initial",
    position: "relative",
    maxWidth: "100%",
  },
  PublishersBg: {
    // backgroundImage: `url(${CategoryBg})`,
    backgroundColor: "#fff",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    margin: 0,
  },
  aboutAuthor: {
    color: "#018fff",
  },
  authorRatingText: {
    color: "#018fff",
  },
  authorRating: {
    display: "flex",
    alignItems: "flex-start",
    position: "relative",
  },
  authorRatingDiv: {
    position: "absolute",
    right: 0,
    display: "flex",
  },
  authorDescription: {
    marginTop: "1rem",
    "& p": {
      marginTop: "0.3rem",
      marginBottom: "0.3rem",
      color: "#003e96",
    },
  },
}))

const AuthorTemplate = ({ data, pageContext }) => {
  const classes = useStyles()
  const [t, i18n] = useTranslation()

  const {
    image,
    pLang,
    strapiId,
  } = pageContext

  useEffect(() => {
    i18n.on("languageChanged", () => {
      windowGlobal.history.pushState(
        pLang,
        `author`,
        `/${i18n.language}/authors/${strapiId}`
      )
      if (windowGlobal.history.pushState) {
        windowGlobal.history.pushState(
          pLang,
          `author`,
          `/${i18n.language}/authors/${strapiId}`
        )
      } else {
        documentGlobal.location.href = `/${i18n.language}/authors/${strapiId}`
      }
    })
  }, [i18n, pLang, strapiId])

  const firstNameForLang =
    i18n.language === "ar"
      ? data.strapiAuthors.firstName_ar
      : i18n.language === "tr"
      ? data.strapiAuthors.firstName_tr
      : i18n.language === "en" && data.strapiAuthors.firstName

  const lastNameForLang =
    i18n.language === "ar"
      ? data.strapiAuthors.lastName_ar
      : i18n.language === "tr"
      ? data.strapiAuthors.lastName_tr
      : i18n.language === "en" && data.strapiAuthors.lastName

  const breadcrumbs = [
    {
      text: t("breadcrumbs.authors"),
      link: `${i18n.language}/authors`,
    },
    {
      text: firstNameForLang,
    },
  ]

  const books = []
  for (let book of data.allStrapiBooks.nodes) {
    let objCopy = Object.assign({}, book)
    books.push(objCopy)
  }

  const lastNameCheck = lastNameForLang ? lastNameForLang : " "
  const firstNameCheck = firstNameForLang ? firstNameForLang : " "

  return (
    <>
      <SEO title={firstNameCheck + " " + lastNameCheck} lang={i18n.language} />
      <Breadcrumbs links={breadcrumbs} />
      <PageTitle
        title={firstNameCheck + " " + lastNameCheck}
        image={image}
        border={true}
      />
      <Container className={`${classes.fullWidth} ${classes.PublishersBg}`}>
        <Container>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography
                variant="h4"
                component="div"
                className={classes.aboutAuthor}
              >
                {t("author.aboutAuthor")}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} className={classes.authorRating}>
              <div className={classes.authorRatingDiv}>
                <Typography
                  variant="subtitle1"
                  component="div"
                  className={classes.authorRatingText}
                >
                  {t("author.rate")}
                </Typography>
                <Rating
                  className={classes.authorRatingStars}
                  name="half-rating-read"
                  defaultValue={
                    data.allStrapiBooks.rating ? data.allStrapiBooks.rating : 0
                  }
                  precision={0.5}
                  readOnly
                />
              </div>
            </Grid>
            <Typography
              component="div"
              className={classes.authorDescription}
              dangerouslySetInnerHTML={{
                __html:
                  i18n.language === "en"
                    ? data.strapiAuthors.description
                    : i18n.language === "ar"
                    ? data.strapiAuthors.description_ar
                    : i18n.language === "tr" &&
                      data.strapiAuthors.description_tr,
              }}
            />
          </Grid>
        </Container>
        <BookList books={books} />
      </Container>
    </>
  )
}
export default AuthorTemplate

export const query = graphql`
  query AuthorTemplate($slug: String!) {
    allStrapiBooks(filter: {author: {slug: {eq: $slug}}, book_items: {elemMatch: {type: {eq: "paper"}}}}) {
      nodes {
        id
        slug
        ISBN
        title
        title_ar
        title_tr
        rating
        quantity
        strapiId
        image {
          publicURL
          childImageSharp {
            fixed(width: 169, height: 244) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        book_items {
          id
          price
          title
          type
        }
        author {
          id
          firstName
          lastName
          slug
          description
          image {
            publicURL
          }
        }
      }
    }
    allStrapiAuthors {
      nodes {
        id
        firstName
        firstName_ar
        firstName_tr
        lastName
        slug
        strapiId
        description
        image {
          publicURL
        }
      }
    }
    strapiAuthors(slug: { eq: $slug }) {
      id
      strapiId
      firstName
      firstName_ar
      firstName_tr
      lastName
      lastName_ar
      lastName_tr
      slug
      description
      description_ar
      description_tr
      image {
        publicURL
      }
    }
  }
`

// childImageSharp {
//   fixed(width: 169, height: 244) {
//     ...GatsbyImageSharpFixed
//   }
// }
